.Toastify__toast-container {
    min-width: 316px !important;
    width: fit-content !important;
}

.Toastify__toast {
    min-height: 50px !important;
}

.Toastify__toast-body {
    color: #384250;
    font-size: 14px;
    font-weight: 500;
}

/* .Toastify__progress-bar--wrp {
    visibility: hidden;
} */

.Toastify__close-button {
    margin-top: 7px;
    padding-right: 7px;
    padding-left: 20px;
}

/* .Toastify__toast-icon svg {
    fill: #16a763;
} */