.css-926sw6-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-926sw6-MuiSelect-select-MuiInputBase-input-MuiInput-input.css-926sw6-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  padding-right: 5px !important;
}

.apexcharts-canvas text.apexcharts-datalabel {
  display: none !important;
}
.background{
  background-color:none;
  width:220px;
  display:flex;
  justify-content: "space-between";
  
  
}
.triangle{
  
  width:20px;
  background-color:none;
 display: flex;
 justify-content: flex-end;
 align-items: center;
 
}

.image1{
  width:20px;
  height:20px;
  
}

.apexcharts-tooltip-custom {
  background-color: #262d33;
  padding: 16px 20px 16px 20px;
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  width: 200px;
  display: flex;
  flex-direction: column;
}




.tool-tip-parent{
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  justify-content: space-between;
  color: #9da4ae;
  font-weight: 400;
  font-size: 12px;
}

.total-count-tip{
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  justify-content: space-between;
  color: #F3F4F6;
  font-weight: 500;
  font-size: 12px;
}

.month-change-top{
  color: #9da4ae;
  font-weight: 400;
  font-size: 12px;
  margin-top: 10px;
}

/* .apexcharts-toolbar {
  display: none !important;
} */
.apexcharts-text tspan{
  font-family: "Inter", sans-serif !important;
  color: #384250 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

