.app {
    display: flex;
    justify-content: center;
  }
  .daterangepicker {
    background-color: #ffffff;
    border: 1px solid #f2f4f7;  
  }
  
  .daterangepicker.show-ranges.ltr .drp-calendar.left {
    font-size: 14px;
    font-weight: 400;
    color: #344054;
    border-left: 1px solid #eaecf0 !important;
    border-right: 1px solid #eaecf0;
    padding: 16px;
    width: 600px;
  }
  
  .daterangepicker .drp-calendar.right {
    font-size: 14px;
    font-weight: 400;
    color: #344054;
    padding: 16px;
    width: 6%;
  }
  .daterangepicker th.month {
    font-size: 16px;
    font-weight: 600;
    color: #344054;
  }
  .daterangepicker .ranges li {
    font-size: 14px;
    font-weight: 500;
    color: #344054;
    padding: 10px 16px 10px 16px;
  }
  
  .daterangepicker .calendar-table th {
    font-size: 14px;
    font-weight: 500;
    color: #344054;
  }
  .cancelBtn {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 10px 16px 10px 16px !important;
    border: 1px solid #d0d5dd;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #344054;
    margin-right: 10px;
    margin-left: initial;
    cursor: pointer;
  }
  .applyBtn {
    border-radius: 8px;
    border: 1px solid #0e5e84;
    background-color: #0e5e84;
    padding: 10px 16px 10px 16px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #ffffff;
    cursor: pointer;
  }
  .daterangepicker .drp-selected {
    margin-right: 190px;
    font-size: 16px;
    font-weight: 400;
    color: #344054;
    border-radius: 8px;
    padding: 10px 14px 10px 14px !important;
    border: 1px solid #d0d5dd;
  }
  
  .daterangepicker .ranges li.active {
    background-color: #f9fafb;
    color: #344054 !important;
  }
  
  .daterangepicker th.month {
    font-weight: 600;
    color: #344054;
  }
  
  .daterangepicker .calendar-table td {
    font-size: 14px;
    font-weight: 400;
    color: #344054;
  }
  .daterangepicker td.active {
    color: #ffffff !important ;
    background-color: #0e5e84 !important;
  }
  .daterangepicker td.start-date {
    border-radius: 20px !important;
  }
  
  .daterangepicker td.end-date {
    border-radius: 30px !important;
  }
  .daterangepicker .calendar-table th,
  .daterangepicker .calendar-table td {
    height: 38px;
  }
  .daterangepicker td.in-range {
    background-color: initial;
    border-color: initial;
    border: initial;
  }
  
  .daterangepicker .calendar-table .next span,
  .daterangepicker .calendar-table .prev span {
    color: #667085;
  }
  
  
  