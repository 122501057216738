@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
* {
  font-family: "Inter", sans-serif;
}

.MuiPaper-root.MuiPopover-paper.MuiMenu-paper {
  margin-top: 13px !important;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.10), 0px 4px 20px 0px rgba(0, 0, 0, 0.15) !important; 
} 
/* .MuiOutlinedInput-notchedOutline {
  border:none !important
} */