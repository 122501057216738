/* MyEditor.css */

/* Customize the editor content area */

.error .ql-editor {
    background-color: #fee4e2 !important;
}

.error .ql-editor.ql-blank::before {
    content: attr(data-placeholder);
    font-style: normal !important;
    font-family: inter, sans-serif !important;
    color: #912018 !important;
    /* Set your desired color */
}

.ql-editor {
    font-family: inter, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    background-color: #f9fafb;
    border: none;
    border-radius: 0 0 8px 8px;
    min-height: 154px;
}

.ql-container.ql-snow {
    border: none;
    /* background-color: #fee4e2; */
}

/* Customize the toolbar */
.ql-toolbar.ql-snow {
    border: none;
    border-radius: 8px 8px 0 0;
}

.ql-toolbar {
    /* border: 1px solid #ccc; */
    background-color: #f9fafb;
    font-family: inter, sans-serif;
    border-bottom: 1px solid #e5e7eb !important;
}

.ql-formats:nth-child(1),
.ql-formats:nth-child(2) {
    border-right: 1px solid rgb(179 176 176 / 34%);
    padding-left: 0;
}

.ql-formats {
    margin-right: 10px !important;
    padding-right: 10px !important;
}

/* Add more custom styles if necessary */
.ql-editor.ql-blank::before {
    content: attr(data-placeholder);
    font-style: normal !important;
    font-family: inter, sans-serif !important;
    color: #9da4ae !important;
    /* Set your desired color */
}